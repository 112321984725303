table {
  background-color: var(--main-color-table);
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border: none;
}

.table_overflow {
  height: auto;
  overflow-x: auto;
  overflow-y: auto;
}

th {
  background-color: var(--main-color-table-2);
  top: 0px;
}

td,
th {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid var(--main-color-table-border);
}
tr {
  cursor: pointer;
}
tr:nth-child(even) {
  background-color: var(--main-color-table-2);
}

.tableWrapper {
  background-color: var(--main-color-table-2);
  border-radius: 25px;
  margin-top: 25px;
  padding: 25px;
  box-shadow: var(--box-shadow);
}
