.chat {
  display: flex;
  height: 50%;
}

.chatMenu {
  flex: 3.5;
}

.chatBoxWrapper {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}

.chatBoxTop {
  height: 84vh;
  overflow-y: scroll;
  padding-right: 10px;
}

.chatBoxBottom {
  height: 10%;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatMenuInput {
  width: 90%;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid gray;
}

.chatBox {
  flex: 5.5;
}

.chatMessageInput {
  width: 80%;
  height: 90px;
  padding: 10px;
}

.chatSubmitButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: teal;
  color: white;
}

.chatOnline {
  flex: 3;
}

.chatMenuWrapper,
.chatBoxWrapper,
.chatOnlineWrapper {
  height: 100%;
}

.noConversationText {
  position: absolute;
  top: 10%;
  font-size: 50px;
  color: lightgrey;
  text-align: center;
  cursor: default;
}
