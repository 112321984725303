.memberPicture {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100%;
  box-shadow: var(--box-shadow);
}

.memberPicture > img {
  max-width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 100%;
  border: 5px solid var(--main-color-2);
  box-shadow: var(--box-shadow-form);
}

.containerMe {
  margin-top: 25px;
  max-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  align-items: stretch;
  grid-auto-rows: 1fr;
}

.logo {
  grid-area: 1/1/1/1;
}

.info {
  grid-area: 2/1/2/1;
}

.resetPass {
  grid-area: 3/1/3/1;
  min-width: 100px;
  max-width: 250px;
  height: 50px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 11px;
  background: var(--main-color-accent-light);
  box-shadow: var(--box-shadow);
  border-radius: 25px;
  color: var(--main-color);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetPass h4 {
  font-size: 20px;
  color: var(--main-color);
}

.col2row1rowSp2 {
  grid-area: 1/2/2/2;
}

.containerCompany {
  margin-top: 25px;
  max-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  align-items: stretch;
  grid-auto-rows: 1fr;
}

.appointmentCompany {
  grid-area: 3/1/3/1;
}

.adresCompany {
  grid-area: 3/2/3/2;
}
