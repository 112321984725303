.modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  text-align: center;
  max-width: 600px;
  margin: 200px auto;
  background-color: var(--main-color);
  border-radius: 45px;
  border: 2px solid var(--main-color-accent-dark);
}

.modal__content button {
  text-align: center;
  background: var(--main-color-2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 50px;
  min-width: 40%;
  border-radius: 45px;
  margin-bottom: 2.5%;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #949494;
}
