#week-container{
    margin-top: 32px;
}

#title {
    margin-bottom: 16px;
}

.week-flexbox{
    display: flex;
    gap: 8px;
    align-items:flex-start;
    flex-wrap: wrap;
}

.add-day{
    width: 100%;
    height: 81px;
    max-width: 336px;
    background-color: lightgray;
    margin-bottom: 32px;
}

.icon-add {
    color: darkslategray;
    font-size: 40px;
    text-align: center;
  }

.delete-icon {
    color: red;
    font-size: x-large;
}
