.container {
  display: flex;
  flex-direction: column;
}

.upperContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.upperLeftContainer {
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.upperLeftContainer h3 {
  margin-top: 0px;
  margin-bottom: 15px;
}

.lowerContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  min-height: 150px;
  align-items: stretch;
  justify-items: stretch;
}

.option1 {
  grid-area: 1/1/2/3;
  text-align: center;
  border: 2px solid var(--main-color-accent-light);
  border-top-left-radius: 15px;
}

.option2 {
  grid-area: 2/1/3/3;
  text-align: center;
  border-left: 2px solid var(--main-color-accent-light);
  border-right: 2px solid var(--main-color-accent-light);
}

.option3 {
  grid-area: 3/1/4/3;
  text-align: center;
  border: 2px solid var(--main-color-accent-light);
  border-bottom-left-radius: 15px;
}

.optionData {
  grid-area: 1/3/4/7;
  text-align: center;
  border-top: 2px solid var(--main-color-accent-light);
  border-right: 2px solid var(--main-color-accent-light);
  border-bottom: 2px solid var(--main-color-accent-light);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 25px;
}

.optionHeader {
  padding-top: 10px;
}

.isActive {
  background-color: red;
}
/* .lowerContainerOptions {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-left: 2px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  border-top-left-radius: 10%;
  border-bottom-left-radius: 10%;
} */

.lowerContainerOptions h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  border-right: 2px solid gray;
  height: 100%;
  width: 100%;
}

.lowerContainerData {
  align-self: center;
  margin: 2%;
}

.containerIframe {
  position: relative;
  width: 45%;
  overflow: hidden;
  padding-top: 25.31%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 25px;
}

.tagBox{
  display: flex;
  margin-bottom: 16px;
  justify-content:left;
}

.tag{
  padding: 10px;
  height: auto;
  width: auto;
  border-radius: 8px;
  margin: 0 8px;
  background-color: var(--main-color-txt);
}

.main-tag{
  background-color: var(--main-color-accent-light);
}

.inputWithTags{
  border: 2px solid transparent;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  width: 100%;
  min-height: 50px;
  background: var(--main-color-form-input);
  box-shadow: var(--box-shadow);
  border-radius: 25px;
  margin-bottom: 25px;
  color: var(--main-color-txt);
}

.tag-title{
  margin: 8px 0 8px;
}