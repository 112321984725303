.contentHeaders {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 2px;
  border-bottom-style: solid;
  border-color: grey;
}
.contentHeaders span:hover {
  cursor: pointer;
}

.contentHeaders .spanActive {
  border-bottom: 3px;
  border-bottom-style: solid;
  border-color: var(--main-color-accent-dark);
  margin-bottom: -2.5px;
  padding-left: 1.25%;
  padding-right: 1.25%;
}

.aboutMembers {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.aboutMembers h2 {
  margin-bottom: 25px;
  color: var(--main-color-txt);
}
.aboutMembers span {
  margin-bottom: 15px;
}

.memberModal {
  position: fixed;
  z-index: 1;
  padding-top: 10%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.memberModal-content {
  display: flex;
  flex-direction: column;
  min-height: 25%;
  max-height: 50%;
  min-width: 40%;
  max-width: 50%;
  justify-content: space-around;
  align-items: center;
  background-color: #fefefe;
  margin: auto;
  border: 2px solid var(--main-color-accent-light);
  border-radius: 45px;
}

.memberModal-content h2 {
  color: var(--main-color-txt);
  text-align: center;
  padding: 2% 20% 5% 20%;
}

.memberModal-content button {
  text-align: center;
  background: var(--main-color-2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 50px;
  width: 150px;
  border-radius: 45px;
  margin-bottom: 2.5%;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #949494;
}

.space{
  margin-left: 8px;
  margin-right:8px;
}