form {
  display: block;
  max-width: 1000px;
  margin: 0 auto;
}

form input {
  padding-left: 2%;
  padding-right: 2%;
  text-align: center;
  width: 100%;
  height: 50px;
  background: var(--main-color-form-input);
  /* border: var(--border-form); */
  box-shadow: var(--box-shadow);
  border-radius: 25px;
  margin-bottom: 25px;
  /* margin-top: 5px; */
  color: var(--main-color-txt);
}

/* .inputFile {
  visibility: hidden;
} */

.inputFile::-webkit-file-upload-button {
  visibility: hidden;
}

.inputFile::before {
  content: "Select some files";
  display: inline-block;
  height: 100%;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  /* padding: 5px 8px; */
  border-radius: 25px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

form textarea {
  padding-left: 2%;
  padding-right: 2%;
  width: 100%;
  height: 150px;
  text-align: center;
  background: var(--main-color-form-input);
  /* border: var(--border-form); */
  box-shadow: var(--box-shadow);
  border-radius: 25px;
  margin-bottom: 25px;
  border: none;
  outline: none;
  color: var(--main-color-txt);
}

form button {
  height: 50px;
  width: 200px;
  color: var(--main-color);
  background-color: var(--main-color-accent-light);
  box-shadow: var(--box-shadow);
  border-radius: 45px;
  font-weight: 600;
  display: block;
  margin: 0 auto;
  text-align: center;
}

form .fsba {
  display: flex;
  height: 100%;
  width: 48%;
  justify-content: space-between;
  align-items: center;
}

form .fsba input {
  width: 160px;
}

form .fsba h4 {
  margin-bottom: 25px;
  margin-right: 10px;
  text-align: left;
}

.radio {
  cursor: pointer;
  max-width: 30px;
  background-color: blue;
}

form h4 {
  margin-top: 20px;
  font-weight: 400;
}

.formButton {
  margin-top: 50px;
}

/* .radio {
  margin-left: 20px;
} */
