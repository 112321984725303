.table-title {
  background-color: var(--main-color-accent-light);
  color: var(--main-color);
}

.special-exercise-title {
  color: var(--main-color);
  background-color: var(--main-color-accent-dark);
  justify-content: space-around;
}

.head-color{
  background-color: var(--main-color-accent-dark);
  color: var(--main-color);
}

.special-exercise {
  background-color: var(--main-color-table-border);
}

.day-table {
  max-width: 336px;
  margin-bottom: 32px;
}

.icon-add-row {
  color: white;
  font-size: 20px;
  margin-bottom: -5px;
}

.table-input {
  border: 1px solid rgb(190, 188, 188);
  border-radius: 6px;
  font-size: large;
  padding: 5px 0 5px;
  color: var(--main-color-txt);
}

.small-table-input {
  width: 40px;
  text-align: center;
}

.tips-table-input {
  width: 150px;
}

.select-table-input {
  min-width: 250px;
}

.title-input {
  width: 150px;
  text-align: start;
}

.table-input::placeholder {
  color: var(--main-color-txt);
  opacity: 1;
}

.table-input::-ms-input-placeholder { /* Edge 12 -18 */
  color: var(--main-color-txt);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.tr-flex {
  display: flex;
  align-items: center;
}

.button-group{
  display: flex;
  justify-content: space-around;
}

.add-exercise-button{
  background-color: var( --main-color-accent-light);
  color: white;
  border-radius: 6px;
  align-items: center;
  padding: 5px;
}

.special-row-button{
  background-color: transparent;
  color: white;
  font-size: x-large;
}