.headerContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-height: 200px;
  margin-bottom: 100px;
}

/* .headerContainer .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color-txt);
  padding: 12.5px;
  border-radius: 25px;
} */

.memberPicture {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100%;
  box-shadow: var(--box-shadow);
}

.memberPicture > img {
  max-width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 100%;
  border: 5px solid var(--main-color-2);
  box-shadow: var(--box-shadow-form);
}

.headerContainer .info {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: space-between;
  margin-left: 5%;
  margin-bottom: 15px;
}

.headerContainer .info h2 {
  margin-top: 0px;
  font-size: 40px;
  font-weight: 600;
  color: var(--main-color-txt);
}

.headerContainer .info span {
  font-size: 18px;
}

.headerContainer .info h3 {
  font-size: 30px;
  color: var(--main-color-txt);
}

.headerContainer .buttons {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 15%;
}
.headerContainer .buttons button {
  height: 50px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  font-weight: 600;
  background-color: var(--main-color-accent-light);
  color: var(--main-color);
}

span:hover {
  cursor: pointer;
  /* text-decoration: underline; */
}

#edit:hover {
  cursor: pointer;
}

#delete:hover {
  cursor: pointer;
}
