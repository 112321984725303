.login {
  display: flex;
  width: 100%;
  height: 100vh;
}

.login_left {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #c4c4c4;
}

.login_right {
  flex: 1.1;
  margin: 5% 5% 0 5%;
}

.loginInput input {
  background-color: #d7d7d7;
  font-weight: 600;
  font-size: 20px;
  width: 80%;
  color: #949494;
  margin: 0;
  border: none;
  box-shadow: none;
}

.loginInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%;
  background: #d7d7d7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 45px;
  font-size: 30px;
  color: #949494;
  padding: 0 2%;
  margin: 4%0;
}

.loginError {
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  width: 100%;
  color: red;
}

.passwordReset {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
}
.passwordReset span {
  font-size: 16px;
  font-weight: 500;
}
