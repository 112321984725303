.layout {
  color: var(--main-color-txt);
}

.layout__content {
  padding-left: var(--sidebar-width);
  background-color: var(--main-bg-white);
  min-height: 100vh;
}

.layout__content-main {
  padding: 30px;
  padding-left: 50px;
}

.layout__content .searchbarWithButton {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.layout__content .searchbarWithButton div .search {
  width: 100%;
  height: 50px;
  background: var(--main-color-2);
}

.layout__content .searchbarWithButton button {
  min-width: 100px;
  max-width: 200px;
  height: 50px;
  padding-left: 25px;
  padding-right: 25px;
  background: var(--main-color-accent-light);
  box-shadow: var(--box-shadow);
  border-radius: 25px;
  color: var(--main-color);
  font-weight: 600;
}

/* Detail header */

.detailHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--main-color-accent-light);
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.delete {
  color: red;
}

.editButton {
  height: 50px;
  width: 150px;
  color: var(--main-color);
  background-color: var(--main-color-accent-light);
  box-shadow: var(--box-shadow);
  border-radius: 45px;
  font-weight: 600;
  display: block;
  margin: 0 0;
  text-align: center;
}
