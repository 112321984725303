.card_container {
  display: flex;
  flex: 1 1 0;
  width: 100%;
  height: 200px;
  /* margin-bottom: 30px; */
  padding: 15px;
  align-items: center;
  transition: color 0.5s ease 0s;
  position: relative;
  overflow: hidden;

  background-color: var(--main-color-2);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.card_info {
  z-index: 1;
}
h4 {
  font-size: 25px;
  font-weight: 600;
  color: var(--main-color-txt);
}
h5 {
  font-size: 23px;
  font-weight: 500;
  color: var(--main-color-txt);
}

span {
  font-size: 20px;
  color: var(--main-color-txt);
}

.card::before {
  content: "";
  width: 100%;
  height: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background-image: linear-gradient(
    to top right,
    var(--main-color-accent-light),
    var(--main-clor-red)
  );
  position: absolute;
  left: -50%;
  top: 0;
  transform: scale(0);
  transition: transform 0.8s ease 0s;
}

.card:hover::before {
  transform: scale(3);
}

.card:hover {
  color: var(--txt-white);
}
