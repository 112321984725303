.searchbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  background: var(--main-color-2);
  box-shadow: var(--box-shadow);
  border-radius: 25px;
  width: 100%;
  height: 50px;
}

.searchbarWithButton .searchbar {
  width: 85%;
}

.searchbar input {
  height: 50px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: var(--main-color-2);
}

.searchbar input::placeholder {
  color: var(--main-color-txt);
}

.searchbarWithButton {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.searchbar .searchbar_Logo {
  width: 5%;
  color: var(--main-color-txt);
}
.searchbar .searchbar_Search {
  margin-left: 20px;
  width: 95%;
  height: 50px;
}
.searchbarWithButton button {
  /* min-width: 100px;
  max-width: 200px;
  height: 50px;
  padding-left: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px; */
}

.optionBox{
  display: flex ;
  flex-direction: column ;
  margin-bottom: 5% ;
  justify-content:left ;
  z-index: 5;
  min-width: 104px;
}

.option{
  padding: 10px;
  height: auto;
  width: auto ;
  border-radius: 8px ;
  margin: 2px;
}

.option-sub{
  background-color: var(--main-color-txt) !important;
}

.selected{
  background-color: darkred !important;
}

.selected-sub{
  background-color: var(--main-color-black) !important;
}
