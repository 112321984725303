.home-title {
  font-size: 50px;
  text-align: center;
  margin-bottom: 20px;
  color: var(--main-color-txt);
  border-bottom: 2px solid var(--main-color-accent-light);
  padding: 0 0 4px;
}

.homeTables {
  color: var(--main-color-txt);
}

/* grid */

.containerCharts {
  max-height: 100vh;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  align-items: stretch;
  grid-auto-rows: 1fr;
}
.activeMembersChart {
  grid-area: 1/1/2/3;
}

.totalExerciseChart {
  grid-area: 1/3/2/5;
}

.createdSchedulesChart {
  grid-area: 2/1/3/3;
}

.schedulesForSaleChart {
  grid-area: 2/3/3/5;
}

.barchartContainer {
  width: 100%;
  grid-area: 1/5/3/8;
  box-shadow: var(--box-shadow);
  background-color: var(--main-color-2);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5%;
  /* border: 1px solid red; */
}

.barchartContainer > h2 {
  margin-top: 20px;
  text-align: center;
}

.coachingRequests {
  background-color: var(--main-color-2);
  max-height: 400px;
  overflow: hidden;
  border-radius: 15px;
  grid-area: 3/1/7/6;
  box-shadow: var(--box-shadow);
}

.latestUpdates{
  background-color: var(--main-color-2);
  max-height: 400px;
  overflow: hidden;
  border-radius: 15px;
  grid-area: 7/1/10/6;
  box-shadow: var(--box-shadow);
}

.unreadComments {
  background-color: var(--main-color-2);
  max-height: 400px;
  overflow: hidden;
  border-radius: 15px;
  grid-area: 3/6/6/8;
  box-shadow: var(--box-shadow);
}

.homeTables {
  margin: 25px 0 -10px 25px;
}

/* .tableWrapperHome {
  background-color: blue;
  border-radius: 25px;
  margin-top: 25px;
  padding: 25px;
  box-shadow: var(--box-shadow);
} */

.approveButton {
  padding-right: 10px;
}

.approveButton:hover{
  color: rgb(0, 123, 255);
}

.denyButton {
  padding-left: 10px;
}

.denyButton:hover{
  color: rgb(255, 4, 0);
}