.confirmModal {
  position: fixed;
  z-index: 1;
  padding-top: 10%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.confirmModalContent {
  display: flex;
  flex-direction: column;
  min-height: 25%;
  max-height: 50%;
  min-width: 40%;
  max-width: 50%;
  justify-content: space-around;
  align-items: center;
  background-color: #fefefe;
  margin: auto;
  border: 2px solid var(--main-color-accent-light);
  border-radius: 45px;
}

.confirmModalContent h2 {
  color: var(--main-color-txt);
  text-align: center;
  padding: 2% 20% 5% 20%;
}

.confirmModalContent button {
  text-align: center;
  background: var(--main-color-2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 50px;
  width: 150px;
  border-radius: 45px;
  margin-bottom: 2.5%;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #949494;
}
