@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  /*
  
  /*STANDARD THEME*/
  --main-color: #ffffff;
  --main-color-2: #fafafb;
  --main-color-black: #010a10;
  --main-color-txt: #455560;
  --main-color-accent-dark: #a50113;
  --main-color-accent-light: #c10206;
  --main-color-table: #fafafb;
  --main-color-table-2: #ffffff;
  --main-color-table-border: rgba(255, 0, 0, 0.341);
  --main-color-form-input: #ffffff;

  --box-shadow: rgba(56, 76, 97, 0.2) 0px 8px 24px;
  --box-shadow-form: rgba(0, 0, 0, 0.25) 0px 2px 2px;

  --border-form: 1px solid var(--main-color-accent-light);

  /*BLUE THEME*/
  /* 
  --main-color: #2c3e50;
  --main-color-2: #2980b9;
  --main-color-black: #010a10;
  --main-color-txt: #fafafb;
  --main-color-accent-dark: #fafafb;
  --main-color-accent-light: #ffffff;
  --main-color-table: #2c3e50;
  --main-color-table-2: #2980b9;
  --main-color-table-border: rgba(250, 250, 250, 0.415);
  --main-color-form-input: #dce9f5;

  --box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  --box-shadow-form: rgba(0, 0, 0, 0.25) 0px 2px 2px;

  --border-form: 1px solid var(--main-color-accent-light); */

  /*RED THEME*/
  /* --main-color: #ea2a40;
  --main-color-2: #c10206;
  --main-color-black: #010a10;
  --main-color-txt: #c4c4c4;
  --main-color-accent-dark: #c4c4c4;
  --main-color-accent-light: #c4c4c4;
  --main-color-table: #a50113;
  --main-color-table-2: #c10206;
  --main-color-table-border: rgba(250, 250, 250, 0.415);
  --main-color-form-input: #c4c4c4;

  --box-shadow: rgba(56, 76, 97, 0.2) 0px 8px 24px;
  --box-shadow-form: rgba(0, 0, 0, 0.25) 0px 2px 2px;

  --border-form: 1px solid var(--main-color-accent-light); */

  /*
  --main-color: #349eff;
  --second-color: #62b4ff;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #349eff;
  --second-color-blue: #62b4ff;

  --main-color-accent-dark: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;

  --main-color-green: #019707;
  --second-color-green: #4caf50;

  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;

  */

  /*SIDEBAR PROPERTIES*/
  --sidebar-width: 300px;
  --border-radius: 15px;
  --topnav-height: 110px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
  background: linear-gradient(
    to right bottom,
    var(--main-color-2),
    var(--main-color)
  );
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: var(--main-color);
}

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--main-color-txt);
  font-weight: 500;
  font-family: inherit;
  background-color: var(--main-color-2);
}

.button-red {
  margin-top: 50px;
  min-width: 100px;
  max-width: 200px;
  height: 50px;
  padding-left: 25px;
  padding-right: 25px;
  background: var(--main-color-accent-light);
  box-shadow: var(--box-shadow);
  border-radius: 25px;
  color: var(--main-color);
  font-weight: 600;
}

ul {
  list-style-type: none;
}

input {
  border: 2px solid transparent;
  outline: 0;
}

input:focus {
  border: 2px solid var(--main-color);
}

.page-header {
  text-transform: capitalize;
  text-align: center;
}

.card {
  padding: 30px;
  margin-bottom: 30px;
  background-color: var(--main-color-2);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
}

.card > div ~ div {
  margin-top: 30px;
}

.card__footer {
  text-align: center;
  text-transform: capitalize;
}

.light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}

.blue-color {
  background-color: var(--main-color-blue);
  color: #fff;
}

.red-color {
  background-color: var(--main-color-accent-dark);
  color: #fff;
}

.cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}

.green-color {
  background-color: var(--main-color-green);
  color: #fff;
}

.orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}

.memberPicture {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100%;
  box-shadow: var(--box-shadow);
}

.memberPicture > img {
  max-width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 100%;
  border: 5px solid var(--main-color-2);
  box-shadow: var(--box-shadow-form);
}
